import { HStack, Text, Tooltip } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { Icon } from "@src/components/ui-kit/Icon";
import { formatDate, formatDuration } from "@src/utils/formatters";
import cs from "classnames";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { Store } from "../../store";

interface DayItemProps {
  item: Store["summaryPerDay"][0];
  onSelect?: (date: Date) => void;
  selected: boolean;
  disabled?: boolean;
  minWidth?: string | number;
  alignCenter?: boolean;
  showDate?: boolean;
}

const DayItem = ({
  item,
  onSelect,
  selected,
  disabled,
  minWidth,
  alignCenter,
  showDate,
}: DayItemProps) => {
  const date = useMemo(() => new Date(item.date), [item.date]);

  const handleOnClick = () => !disabled && onSelect?.(date);

  return (
    <div
      onClick={handleOnClick}
      className={cs("TimeSheet__SummaryItem py-4 px-8", {
        "bg-skyLight": selected,
        "cursor-pointer hover:bg-skyLight": !disabled,
        "text-center": alignCenter,
      })}
      style={{ minWidth }}
    >
      <Text pb="1" color="grey.500" whiteSpace="nowrap">
        {format(date, "EEEE")}
      </Text>
      {showDate && (
        <Text pb="1" color="grey.500" fontSize="xs" whiteSpace="nowrap">
          {formatDate(date)}
        </Text>
      )}
      <HStack justify="space-between">
        <p className="text-2xl">
          {formatDuration(item.tracked_time, { noPadStart: true })}
        </p>
        {item.auto_stopped && (
          <Tooltip
            label={<Trans>This item has been automatically stopped.</Trans>}
            placement="top"
            shouldWrapChildren
          >
            <Icon name="alert-triangle" color="red.500" />
          </Tooltip>
        )}
      </HStack>
    </div>
  );
};

export default observer(DayItem);
