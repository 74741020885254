import { Box } from "@chakra-ui/react";
import cs from "classnames";
import { isSameDay } from "date-fns";
import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "../store";
import { EmptyEntries, LoadingEntries, WeekEntry } from "./Entries";
import s from "./Entries/WeekEntry.module.css";
import { DayItem, WeekTotal } from "./SummaryPerDay";

const WeekMode = () => {
  const store = useStore();

  return (
    <Box
      minW={{
        base: "1140px",
        md: "unset",
      }}
    >
      <div className={cs(s.EntryItemWrapper, "pb-8")}>
        <span />
        {store.summaryPerDay.map((i) => (
          <DayItem
            key={i.date.toLocaleString()}
            item={i}
            selected={isSameDay(new Date(), new Date(i.date))}
            disabled
            showDate
          />
        ))}
        <WeekTotal items={store.summaryPerDay} />
      </div>
      {(store.weekTimeReportLoading && <LoadingEntries />) || (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <React.Fragment>
          {!store.weekRows.length ? (
            <EmptyEntries />
          ) : (
            <div className="divide-y divide-gray-200">
              {store.weekRows.map((i) => (
                <WeekEntry key={i.id} item={i} />
              ))}
            </div>
          )}
        </React.Fragment>
      )}
    </Box>
  );
};

export default observer(WeekMode);
